import Grid from '@material-ui/core/Grid'
import Head from '../components/informational/Head'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  grid: {
    height: 'calc( 100vh - 85px)'
  }
}

const ErrorPage = ({ classes }) => (
  <>
    <Head title='Error' />

    <Grid
      container
      direction='row'
      justify='center'
      alignItems='center'
      className={classes.grid}
    >
      <Grid item xs={12} align='center'>
        <Typography align='center' variant='h1' gutterBottom>
          Download failed :/
        </Typography>
        <Typography align='center' variant='body1' gutterBottom>
          Unfortunately the given url couldn't be downloaded because the url is not working or is blocked. Try entering a different url.
        </Typography>
      </Grid>
    </Grid>
  </>
)

export default withStyles(styles)(ErrorPage)
